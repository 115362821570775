import React from "react";
import { graphql } from "gatsby";
import ProductionProgrammeIntro from "../components/page-components/ProductionProgrammes/ProductionProgrammesIntro";
import PluginKeyFeatures from "../components/reusable/PluginKeyFeatures";
import PluginQuoteSlider from "../components/reusable/PluginQuoteSlider";
import PluginTextImageBlock from "../components/reusable/PluginTextImageBlock";
import PluginAccordionBlock from "../components/reusable/PluginAccordionBlock";
import PluginSystemRequirements from "../components/reusable/PluginSystemRequirements";
import Seo from "../components/global/seo";

export default function ProductTemplate(props) {
  const pageData = props.data.wpProduct;
  const path = props.location.pathname;

  function camelize(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }
  return (
    <div>
      <Seo title={pageData.seo.title} description={pageData.seo.metaDesc} />
      <ProductionProgrammeIntro
        video={pageData.acf_product_info.templates.pluginTrailerVideo}
        introVideoObjectFit={"contain"}
        title={pageData.title}
        path={path}
        introText={pageData.acf_product_info.templates.description}
        snipcart_meta={camelize(pageData.title) + "-" + pageData.id}
        snipcart_plugin={true}
        snipcart_description={pageData.acf_product_info.templates.briefProductDescription}
        snipcart_id={pageData.databaseId}
        snipcart_price={pageData.acf_product_info.templates.price}
        snipcart_image={
          pageData?.acf_product_info?.templates?.pluginThumbnailImage.localFile.publicURL
        }
        snipcart_guid={pageData.acf_product_info.templates.snipcartGuid}
        category="ableton-template"
      />

      <PluginTextImageBlock
        data={pageData.acf_product_info.templates.textImageBlock}
        layout={"text-left-image-right"}
        isAbletonTemplate={true}
        title={pageData.title}
        path={path}
        paddingBottom={true}
        snipcart_meta={camelize(pageData.title) + "-" + pageData.id}
        snipcart_plugin={true}
        snipcart_description={pageData.acf_product_info.templates.briefProductDescription}
        snipcart_id={pageData.databaseId}
        snipcart_price={pageData.acf_product_info.templates.price}
        snipcart_image={
          pageData?.acf_product_info?.templates?.pluginThumbnailImage.localFile.publicURL
        }
        snipcart_guid={pageData.acf_product_info.templates.snipcartGuid}
        category="ableton-template"
      />
      {/* <PluginAccordionBlock
        data={pageData.acf_product_info.templates.accordion}
      /> */}
    </div>
  );
}

export const query = graphql`
  query ($id: String!) {
    wpProduct(id: { eq: $id }) {
      id
      seo {
        title
        metaDesc
      }
      title
      databaseId
      acf_product_info {
        templates {
          description
          snipcartGuid
          briefProductDescription
          accordion {
            accordion {
              accordionText
              accordionTitle
            }
            accordionTitle
          }
          pluginTrailerVideo
          pluginThumbnailImage {
            localFile {
              publicURL
            }
          }
          price

          salePrice
          snipcartGuid
          textImageBlock {
            buttonText

            text
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
            }
          }
        }
      }
    }
  }
`;
